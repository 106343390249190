
<mat-form-field appearance="outline" subscriptSizing="dynamic" [ngClass]="{ small: size === 'small' }">
  @if (iconPosition === 'prefix') {
    <mat-icon matPrefix>search</mat-icon>
  }
  @if (iconPosition === 'suffix') {
    <mat-icon matSuffix>search</mat-icon>
  }
  <input matInput inputTrim type="text" placeholder="Search" (keydown.enter)="closeKeyboard()" [formControl]="searchCtrl" #search>
</mat-form-field>
