import { NgClass } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'app-search-field',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatIconModule, NgClass, ReactiveFormsModule],
  templateUrl: './search-field.component.html',
  styleUrl: './search-field.component.scss'
})
export class SearchFieldComponent implements OnDestroy, OnInit {
  @Input() size: 'small' | 'default';
  private _query: string;
  @Input()
  // I am choosing a set function over using the onChange event
  set query(val: string) {
    this._query = val;
    this.searchCtrl.setValue(this._query);
  }
  @Input() iconPosition: 'prefix' | 'suffix' = 'prefix'
  @Output() executeSearch = new EventEmitter<string>();

  @ViewChild('search') searchElement: ElementRef;
  searchCtrl = new FormControl<string>('');
  searchSubscription: Subscription;

  constructor() {
    this.searchSubscription = this.searchCtrl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(val => {
      if (val && val.length >= 2 ) {
        console.log('Doing a search', val);
        this.executeSearch.emit(val);
      }
    })
  }

  reset() {
    this.searchCtrl.reset();
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }

  closeKeyboard() {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  ngOnInit(): void {
    if (this._query) {
      this.searchCtrl.setValue(this._query);
      // I do not want to add a afterViewInit hook just for the focus
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0)
    }
  }
}
